import React from "react";
import "./styles.css";

const LGPD = () => {
  const handleClick = () => {
    document.getElementById("lgpd").style.display = "none";
  };

  const open = () => {
    document.getElementById("politica").style.display = "flex";
    document.getElementById("lgpd").style.display = "none";
  };

  const close = () => {
    document.getElementById("politica").style.display = "none";
  };

  return (
    <div id="termos">
      <div id="lgpd">
        <div className="lei">
          Para personalizar conteúdo utilizamos Cookies, de acordo com nossos{" "}
          <button className="pol" onClick={open}>
            termos
          </button>{" "}
          ao continuar, você concorda com as condições.
          <button className="btna" onClick={handleClick}>
            {" "}
            Aceito{" "}
          </button>
        </div>
      </div>

      <div id="politica">
        <div className="fec">
          <h1>Politica de Privacidade</h1>
          <button className="close" onClick={close}>
            {" "}
            &#10006;{" "}
          </button>
        </div>

        <div id="tp">
          <p>
            <strong>SEÇÃO 1 - O QUE FAREMOS COM ESTA INFORMAÇÃO?</strong> <br />
            Quando você realiza o preenchimento de formulário de nosso site,
            como parte do processo de compra e venda, coletamos as informações
            pessoais que você nos dá tais como: nome, e-mail e endereço. Quando
            você acessa nosso site, também recebemos automaticamente o protocolo
            de internet do seu computador, endereço de IP, a fim de obter
            informações que nos ajudam a aprender sobre seu navegador e sistema
            operacional. E-mail Marketing será realizado apenas caso você
            permita. Nestes e-mails você poderá receber notícia sobre nossa
            agência, novos produtos e outras atualizações.
          </p>
          <p>
            <strong>SEÇÃO 2 - CONSENTIMENTO</strong> <br />
            Como vocês obtêm meu consentimento? Quando você fornece informações
            pessoais como nome, telefone e endereço, para completar: preenchendo
            um formulário. Após a realização de ações entendemos que você está
            de acordo com a coleta de dados para serem utilizados pela nossa
            empresa. Se pedimos por suas informações pessoais por uma razão
            secundária, como marketing, vamos lhe pedir diretamente por seu
            consentimento, ou lhe fornecer a oportunidade de dizer não. E caso
            você queira retirar seu consentimento, como proceder? Se após você
            nos fornecer seus dados, você mudar de ideia, você pode retirar o
            seu consentimento para que possamos entrar em contato, para a
            coleção de dados contínua, uso ou divulgação de suas informações, a
            qualquer momento, entrando em contato conosco.
          </p>
          <p>
            <strong>SEÇÃO 3 - DIVULGAÇÃO</strong> <br />
            Podemos divulgar suas informações pessoais caso sejamos obrigados
            pela lei para fazê-lo ou se você violar nossos Termos de Serviço.
          </p>
          <p>
            <strong>SEÇÃO 4 - SEGURANÇA</strong> <br />
            Para proteger suas informações pessoais, tomamos precauções
            razoáveis e seguimos as melhores práticas da indústria para nos
            certificar que elas não serão perdidas inadequadamente, usurpadas,
            acessadas, divulgadas, alteradas ou destruídas.
          </p>
          <p>
            <strong>
              SEÇÃO 5 - ALTERAÇÕES PARA ESSA POLÍTICA DE PRIVACIDADE
            </strong>
            <br />
            Reservamos o direito de modificar essa política de privacidade a
            qualquer momento, então por favor, revise-a com frequência.
            Alterações e esclarecimentos vão surtir efeito imediatamente após
            sua publicação no site. Se fizermos alterações de materiais para
            essa política, iremos notificá-lo aqui que eles foram atualizados,
            para que você tenha ciência sobre quais informações coletamos, como
            as usamos, e sob que circunstâncias, se alguma, usamos e/ou
            divulgamos elas. Se nossa agência for adquirida ou fundida com outra
            empresa, suas informações podem ser transferidas para os novos
            proprietários para que possamos continuar a vender produtos para
            você.
          </p>
          <p>
            <strong>SEÇÃO 6 - POLÍTICA DE COOKIES</strong> <br />O que são
            cookies? Como é prática comum em quase todos os sites profissionais,
            este site usa cookies, que são pequenos arquivos baixados no seu
            computador, para melhorar sua experiência. Esta página descreve
            quais informações eles coletam, como as usamos e por que às vezes
            precisamos armazenar esses cookies. Também compartilharemos como
            você pode impedir que esses cookies sejam armazenados, no entanto,
            isso pode fazer o downgrade ou 'quebrar' certos elementos da
            funcionalidade do site. Como usamos os cookies? Utilizamos cookies
            por vários motivos, detalhados abaixo. Infelizmente, na maioria dos
            casos, não existem opções padrão do setor para desativar os cookies
            sem desativar completamente a funcionalidade e os recursos que eles
            adicionam a este site. É recomendável que você deixe todos os
            cookies se não tiver certeza se precisa ou não deles, caso sejam
            usados ​​para fornecer um serviço que você usa. Desativar cookies:
            Você pode impedir a configuração de cookies ajustando as
            configurações do seu navegador (consulte a Ajuda do navegador para
            saber como fazer isso). Esteja ciente de que a desativação de
            cookies afetará a funcionalidade deste e de muitos outros sites que
            você visita. A desativação de cookies geralmente resultará na
            desativação de determinadas funcionalidades e recursos deste site.
            Portanto, é recomendável que você não desative os cookies.
          </p>
          <p>
            <strong>Cookies que definimos</strong> <br />
            Cookies relacionados à conta: Se você criar uma conta connosco,
            usaremos cookies para o gerenciamento do processo de inscrição e
            administração geral. Esses cookies geralmente serão excluídos quando
            você sair do sistema, porém, em alguns casos, eles poderão
            permanecer posteriormente para lembrar as preferências do seu site
            ao sair. Cookies relacionados ao login: Utilizamos cookies quando
            você está logado, para que possamos lembrar dessa ação. Isso evita
            que você precise fazer login sempre que visitar uma nova página.
            Esses cookies são normalmente removidos ou limpos quando você efetua
            logout para garantir que você possa acessar apenas a recursos e
            áreas restritas ao efetuar login. Cookies relacionados a boletins
            por e-mail: Este site oferece serviços de assinatura de boletim
            informativo ou e-mail e os cookies podem ser usados ​​para lembrar
            se você já está registrado e se deve mostrar determinadas
            notificações válidas apenas para usuários inscritos / não inscritos.
            Pedidos processando cookies relacionados: Este site oferece
            facilidades de comércio eletrônico ou pagamento e alguns cookies são
            essenciais para garantir que seu pedido seja lembrado entre as
            páginas, para que possamos processá-lo adequadamente. Cookies
            relacionados a pesquisas: Periodicamente, oferecemos pesquisas e
            questionários para fornecer informações interessantes, ferramentas
            úteis ou para entender nossa base de usuários com mais precisão.
            Essas pesquisas podem usar cookies para lembrar quem já participou
            numa pesquisa ou para fornecer resultados precisos após a alteração
            das páginas. Cookies relacionados a formulários: Quando você envia
            dados por meio de um formulário como os encontrados nas páginas de
            contacto ou nos formulários de comentários, os cookies podem ser
            configurados para lembrar os detalhes do usuário para
            correspondência futura. Cookies de preferências do site: Para
            proporcionar uma ótima experiência neste site, fornecemos a
            funcionalidade para definir suas preferências de como esse site é
            executado quando você o usa. Para lembrar suas preferências,
            precisamos definir cookies para que essas informações possam ser
            chamadas sempre que você interagir com uma página for afetada por
            suas preferências.
          </p>
          <p>
            <strong>Cookies de Terceiros</strong> <br />
            Em alguns casos especiais, também usamos cookies fornecidos por
            terceiros confiáveis. A seção a seguir detalha quais cookies de
            terceiros você pode encontrar através deste site. Este site usa o
            Google Tag Manager, Google Analytics, Google Ads e Facebook Ads, que
            são algumas das soluções de análise mais difundidas e confiáveis
            ​​da Web, para nos ajudar a entender como você usa o site e como
            podemos melhorar sua experiência. Esses cookies podem rastrear itens
            como quanto tempo você gasta no site e as páginas visitadas, para
            que possamos continuar produzindo conteúdo atraente. Para mais
            informações sobre cookies do Google Tag Manager, Google Analytics,
            Google Ads e Facebook Ads, consulte as respectivas páginas oficiais
            do Google Tag Manager, Google Analytics, Google Ads e Facebook Ads.
            As análises de terceiros são usadas para rastrear e medir o uso
            deste site, para que possamos continuar produzindo conteúdo
            atrativo. Esses cookies podem rastrear itens como o tempo que você
            passa no site ou as páginas visitadas, o que nos ajuda a entender
            como podemos melhorar o site para você. Periodicamente, testamos
            novos recursos e fazemos alterações subtis na maneira como o site se
            apresenta. Quando ainda estamos testando novos recursos, esses
            cookies podem ser usados ​​para garantir que você receba uma
            experiência consistente enquanto estiver no site, enquanto
            entendemos quais otimizações os nossos usuários mais apreciam. À
            medida que vendemos produtos, é importante entendermos as
            estatísticas sobre quantos visitantes de nosso site realmente
            compram e, portanto, esse é o tipo de dados que esses cookies
            rastrearão. Isso é importante para você, pois significa que podemos
            fazer previsões de negócios com precisão que nos permitem analizar
            nossos custos de publicidade e produtos para garantir o melhor preço
            possível.
          </p>
          <p>
            <strong>Mais informações:</strong> <br />
            Esperemos que esteja esclarecido e, como mencionado anteriormente,
            se houver algo que você não tem certeza se precisa ou não,
            geralmente é mais seguro deixar os cookies ativados, caso interaja
            com um dos recursos que você usa em nosso site. Esta política é
            efetiva a partir de Agosto de 2020.
          </p>
          <div className="acc">
            <button className="btna" onClick={close}>
              Aceito
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LGPD;
