import React from "react";
import "./styles.css";

import logo_rodape from "../../assets/logo_rodape.png";
import footer1_1 from "../../assets/footer1_1.png";
import footer1_2 from "../../assets/footer1_2.png";
import footer1_3 from "../../assets/footer1_3.png";

const Footer = () => {
  return (
    <footer id="footer">
      <div className="footer1">
        <div className="container">
          <div className="content">
            <figure className="logo">
              <img src={logo_rodape} alt="Logo" />
            </figure>

            <div className="item">
              <p className="item_title">ENDEREÇO</p>

              <a
                href="https://maps.app.goo.gl/hQPEpJ5kfTEuYKb98"
                target="_blank"
                rel="noreferrer"
                className="item_text"
              >
                199 Rua Alvorada, Vila Olímpia, SP, 04550-000, Brasil
              </a>
            </div>

            <div className="item">
              <p className="item_title">CONTATOS</p>

              <div className="ctas">
                <div className="cta">
                  <figure>
                    <img src={footer1_1} alt="Ícone de Telefone" />
                  </figure>

                  <a href="tel:973460039" className="item_text">
                    (11) 97346-0039
                  </a>
                </div>

                <div className="cta">
                  <figure>
                    <img src={footer1_2} alt="Ícone de Whatsapp" />
                  </figure>

                  <a
                    href="http://wa.me/+5511973460039?text=Olá,%20Gostaria%20de%20saber%20mais"
                    target="_blank"
                    rel="noreferrer"
                    className="item_text"
                  >
                    (11) 97346-0039
                  </a>
                </div>

                <div className="cta">
                  <figure>
                    <img src={footer1_3} alt="Ícone de Email" />
                  </figure>

                  <a href="mailto:contato@goodboy.com.br" className="item_text">
                    contato@goodboy.com.br
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer2">
        <div className="container">
          <p className="text">
             Copyright 2024 - Good Boy Dog Walker - Todos os direitos reservados
          </p>

          <p className="text">
            Desenvolvido por{" "}
            <a href="https://magencia.com.br" target="_blank" rel="noreferrer">
              M Agência Digital
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
