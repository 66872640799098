import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles.css";

const Carousel = ({ arr, isVideo, slidesToShow }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
  };

  return (
    <article className="carousel">
      <Slider {...settings}>
        {arr.map((element, index) => (
          <div key={index}>
            {isVideo ? (
              <video controls className="item">
                <source src={element.video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <img
                src={element.image}
                alt={`Slide ${index}`}
                className="item"
              />
            )}
          </div>
        ))}
      </Slider>
    </article>
  );
};

export default Carousel;
