import "./styles.css";

import CtaWpp from "../../components/CTAWpp";
import Carousel from "../../components/Carousel";

import about1_1 from "../../assets/about1_1.png";
import about1_2 from "../../assets/about1_2.png";
import about1_3 from "../../assets/about1_3.png";
import about1_4 from "../../assets/about1_4.png";
import about1_5 from "../../assets/about1_5.png";
import about2_1 from "../../assets/about2_1.png";
import about2_2 from "../../assets/about2_2.png";
import about2_3 from "../../assets/about2_3.png";
import about2_4 from "../../assets/about2_4.png";
import about2_5 from "../../assets/about2_5.png";
import about2_6 from "../../assets/about2_6.png";
import about2_7 from "../../assets/about2_7.png";
import about2_8 from "../../assets/about2_8.png";
import about2_9 from "../../assets/about2_9.png";
import about2_10 from "../../assets/about2_10.png";
import about2_11 from "../../assets/about2_11.png";
import about2_12 from "../../assets/about2_12.png";
import about2_13 from "../../assets/about2_13.png";
import about2_14 from "../../assets/about2_14.png";
import about2_15 from "../../assets/about2_15.png";
import about2_16 from "../../assets/about2_16.png";
import about2_17 from "../../assets/about2_17.png";
import about2_18 from "../../assets/about2_18.png";

const about1_gallery = [
  {
    image: about1_1,
  },
  {
    image: about1_2,
  },
  {
    image: about1_3,
  },
  {
    image: about1_4,
  },
  {
    image: about1_5,
  },
];

const about2_gallery = [
  {
    image: about2_1,
  },
  {
    image: about2_2,
  },
  {
    image: about2_3,
  },
  {
    image: about2_4,
  },
  {
    image: about2_5,
  },
  {
    image: about2_6,
  },
  {
    image: about2_7,
  },
  {
    image: about2_8,
  },
  {
    image: about2_9,
  },
  {
    image: about2_10,
  },
  {
    image: about2_11,
  },
  {
    image: about2_12,
  },
  {
    image: about2_13,
  },
  {
    image: about2_14,
  },
  {
    image: about2_15,
  },
  {
    image: about2_16,
  },
  {
    image: about2_17,
  },
  {
    image: about2_18,
  },
];

const About = () => {
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  return (
    <section id="about">
      <article className="about1">
        <div className="container vertical">
          <h2 className="title">
            O QUE NOSSOS CLIENTES FALAM SOBRE NOSSO SERVIÇOS
          </h2>

          <p className="text">GOOGLE COMENTÁRIOS SOBRE A GOOD BOY</p>
          
          {isMobile ? (
            <div className="mobile">
              {about1_gallery.map(({ image }, index) => (
                <figure key={index}>
                  <img src={image} alt="Foto" />
                </figure>
              ))}
            </div>
          ) : (
            <Carousel
              arr={about1_gallery}
              isVideo={false}
              slidesToShow={isMobile ? 1 : 4}
            />
          )}

          <CtaWpp />
        </div>
      </article>

      <article className="about2">
        <div className="container vertical">
          <h2 className="title">GALERIA DE FOTOS</h2>

          <p className="text">VEJA AS FOTOS DOS NOSSO AMIGOS DE 4 PATAS</p>

          <Carousel
            arr={about2_gallery}
            isVideo={false}
            slidesToShow={isMobile ? 1 : 3}
          />

          <p className="text text2">Traga o seu pet para a Good Boy!</p>

          <CtaWpp />
        </div>
      </article>

      <article className="about3">
        <div className="container vertical">
          <h2 className="title">
            ÁREA DE ABRANGÊNCIA DOG WALKER GOOD BOY SÃO PAULO-SP.
          </h2>

          <ul className="gallery">
            <li>Alto da Lapa</li>
            <li>Indianápolis</li>
            <li>Jardins</li>
            <li>Vila Leopoldina</li>
            <li>Brooklin</li>
            <li>Itaim Bibi</li>
            <li>Moema</li>
            <li>Vila Madalena</li>
            <li>Campo Bello</li>
            <li>Jardim Europa</li>
            <li>Pinheiros</li>
            <li>Vila Mariana</li>
            <li>Cidade Jardim</li>
            <li>Jardim Luzitânia</li>
            <li>Sumare</li>
            <li>Vila Nova Conceição</li>
            <li>Ibirapuera</li>
            <li>Jardim Paulistano</li>
            <li>Vila Clementino</li>
            <li>Vila Olimpia</li>
          </ul>

          <CtaWpp />
        </div>
      </article>
    </section>
  );
};

export default About;
