import "./styles.css";

import CtaWpp from "../../components/CTAWpp";
import Carousel from "../../components/Carousel";

import header2_1 from "../../assets/header2_1.png";
import header2_2 from "../../assets/header2_2.png";
import header2_3 from "../../assets/header2_3.png";
import header2_4 from "../../assets/header2_4.png";
import header2_5 from "../../assets/header2_5.png";
import header3 from "../../assets/header3.png";

const header2_gallery = [
  {
    image: header2_1,
  },
  {
    image: header2_2,
  },
  {
    image: header2_3,
  },
  {
    image: header2_4,
  },
  {
    image: header2_5,
  },
];

const Header = () => {
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  return (
    <header id="header">
      <section className="header1">
        <article className="container">
          <h1 className="title">
            GOOD BOY <span>&nbsp; DOG WALKER &nbsp;</span>
          </h1>

          <h2 className="text">
            O serviço mais completo de passeador de cães em São Paulo-SP.
          </h2>

          <CtaWpp />
        </article>
      </section>

      <section className="header2">
        <article className="container vertical">
          <h2 className="title">
            O QUE ESPERAR DO PASSEIO COM DOG WALKER DA GOOD BOY?
          </h2>

          <p className="text">
            Faça chuva ou faça sol, nossos dog walkers passeiam por cerca de 6
            quilômetros em 2 horas a cada passeio em um lugar que os cães amam
            mais que qualquer outro – o lindíssimo Parque Ibirapuera.
          </p>

          {isMobile ? (
            <div className="mobile">
              {header2_gallery.map(({ image }, index) => (
                <figure key={index}>
                  <img src={image} alt="Foto" />
                </figure>
              ))}
            </div>
          ) : (
            <Carousel
              arr={header2_gallery}
              isVideo={false}
              slidesToShow={isMobile ? 1 : 4}
            />
          )}

          <CtaWpp />
        </article>
      </section>

      <section className="header3">
        <article className="container">
          <div className="left">
            <h2 className="title">NOSSO DIFERENCIAL</h2>

            <figure className="mobile">
              <img src={header3} alt="Cachorros em um parque" />
            </figure>

            <p className="text">
              Não somos Dog Walkers convêncionais. Somos o segundo melhor amigo
              do seu cão, somos educadores e guias para se firmar e encontrar
              suas raízes na confiança, paz e equilíbrio no mundo humano. O
              passeador de cães da Good Boy proporciona para o seu peludo a
              chance de aproveitar ao máximo a vida canina ao ar livre, com
              outros amigos de quatro-patas. Se é de atividade física e mental,
              socialização e diversão que o seu cão precisa, conheça o serviço
              de dog walker da Good Boy.
            </p>

            <CtaWpp />
          </div>

          <figure className="right">
            <img src={header3} alt="Cachorros em um parque" />
          </figure>
        </article>
      </section>
    </header>
  );
};

export default Header;
