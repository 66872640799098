import React from "react";
import "./styles.css";

import CtaTel from "../../components/CTATel";

import menu1 from "../../assets/menu1.png";
import logo_topo from "../../assets/logo_topo.png";

const Menu = () => {
  return (
    <nav id="menu">
      <section className="container">
        <article className="left">
          <figure>
            <img src={menu1} alt="Localização" />
          </figure>

          <p className="text">
            <a
              href="https://maps.app.goo.gl/hQPEpJ5kfTEuYKb98"
              target="_blank"
              rel="noreferrer"
            >
              199 Rua Alvorada, Vila Olímpia, SP, 04550-000, Brasil
            </a>
          </p>
        </article>

        <figure className="logo">
          <img src={logo_topo} alt="Logo" />
        </figure>

        <article className="right">
          <p className="text">Fale conosco por telefone:</p>

          <CtaTel />
        </article>
      </section>
    </nav>
  );
};

export default Menu;
